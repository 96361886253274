(function ($) {
    jQuery.extend(verge);
    var timer;
    $(document).ready(function () {
        var mobileNavId = "#mobile-primary-navigation";
        if ($(mobileNavId).length > 0) {
            $(mobileNavId).multilevelpushmenu({
                mode: 'cover',
                containersToPush: [$('header'), $('.footer-main'), $('main')],
                menuWidth: '100%',
                menuHeight: '100%'
            });
            $(window).resize(function () {
                $(mobileNavId).multilevelpushmenu('redraw');
            });
        }

        //Initialize the clickable links
        Marathon.SetLinksAsClickable.OnReady();

        //Kickoff the mega-menu adjustment
        adjustMenu();

        // ZipCode Lookup
        var $zipcodeForm = $('.zipcodecheck');
        if ($zipcodeForm.length > 0 && typeof applicationUrl !== 'undefined') {
            $zipcodeForm.each(function () {
                var $thisForm = this;
                var $errorDiv = $('.error-message', $thisForm);

                // Custome cfs url can be provided
                var localApplicationUrl = $($thisForm).data("customcfs");
                if (!localApplicationUrl.length) {
                    localApplicationUrl = applicationUrl;
                }

                if ($errorDiv.length > 0) {
                    $('.btn', $thisForm).on('click', function () {
                        $errorDiv.html('').hide();
                        var zipValue = $.trim($('input', $thisForm).val());
                        var isValid = /^\d{5}$/.test(zipValue);
                        if (isValid) {
                            $(this).html("Processing...").attr('disabled', 'disabled');
                            document.location.href = localApplicationUrl + "/Account/SignUp/" + zipValue;
                        }
                        else {
                            $errorDiv.html('Please enter a valid 5 digit zip code.').show();
                            $('input', $thisForm).focus();
                        }
                        return false;
                    });

                }
            });
        }
        
        $(".image-gallery-thumbnail").colorbox({
            rel: 'gallery-thumb',
            transition: 'fade',
            maxWidth: '95%',
            maxHeight: '95%'
        });

        $(window).on('resize', function () {
            clearTimeout(timer);
            timer = setTimeout(adjustMenu, 50);
        });


        //stellar parallax
        $.stellar.positionProperty.onit = {
            setPosition: function ($el, x, startX, y, startY) {
                $el.css('transform', 'translate3d(' +
                    (x - startX) + 'px, ' +
                    (y - startY) + 'px, ' +
                    '0)');
            }
        };

        $.stellar({
            horizontalScrolling: false,
            verticalScrolling: true,
            horizontalOffset: 0,
            verticalOffset: 0,
            //scrollProperty: 'transform',
            positionProperty: 'onit',
            hideDistantElements: true
        });

        //skip content click
        $(".skip-content").click(function (e) {
            e.preventDefault();
            $("html").animate({ scrollTop: ($("#skip-destination").offset().top - ($("header").height() + 20)) }, '200');
        });


        $('[data-toggle="tooltip"]').tooltip();

    });
})(jQuery);

function adjustMenu() {
    var i = 1;

    $(".megamenu:visible").each(function (e) {


        var elm = $(this);
        var mm = $(elm).children('.dropdown-menu').first();

        //mm.removeAttr('style');
        $(mm).removeClass('center');
        $(elm).css('position', 'relative');

        var off = $(mm).offset();
        var l = off.left; // position of right of parent item
        var ddCenter = (Math.ceil(($(elm).width() / 2)) + l); // Center point of the parent menu item from the left
        var w = $(mm).outerWidth(); // mEGA MENU WIDTH

        var docH = $(document).height();
        var docW = $(document).width();

        //console.log('right edge -' + rightEdge + ' right offset - ' + rightEdgeOffset);
        //console.log(elm.text().trim().substr(0, 15));
        //console.log("left offset - " + l + " docW - " + docW);

        var offsetVal = docW - (l + w);

        //console.log(i + " nav width: " + ($('nav').width() * .5));
        //console.log(i + " item width: " + w);
        //console.log(offsetVal);

        if (l < 0) {
            //alert("yep" + elm.text());
            $(mm).addClass('center');
            $(elm).css('position', 'static');
            //var rightText = "right: " + offsetVal + "px!important";
            //$(mm).css('right', (15 - offsetVal) + "px");

        }
        else {
            //$(mm).css('right', 0);
            $(mm).removeClass('center');
            $(elm).css('position', 'relative');
        }
        i++;
    });
}
function tagIE() {
    var myNav = navigator.userAgent.toLowerCase();
    var is10orbelow = (myNav.indexOf('msie') != -1) ? parseInt(myNav.split('msie')[1]) : false;
    if (is10orbelow)  // If Internet Explorer, return version number
    {
        //add ie classd to html
        var root = document.getElementsByTagName('html')[0]; // '0' to assign the first (and only `HTML` tag)
        root.className += ' ie10below';
        //$("html").addClass("ie10below");
    }
    else {
        var isIE11 = !!(navigator.userAgent.match(/Trident/) && navigator.userAgent.match(/rv[ :]11/));
        if (isIE11) {
            var root = document.getElementsByTagName('html')[0]; // '0' to assign the first (and only `HTML` tag)
            root.className += ' ie11';
        }
    }
}
tagIE();