//To use this functionality, simply add the class 'link-enabled' onto any container element that contains an anchor tag within it
//If the container features more than 1 child anchor tag, you can specify which anchor should take precedence by adding the 'preferred-link' class to that anchor element

var Marathon = (function ($) {
    this.SetLinksAsClickable = {
        OnReady: function () {

            $('.preferred-link').each(function () {
                var dataTag = $(this).closest('[data-link-enabled]');
                if (dataTag.exists()) dataTag.addClass('link-enabled');
            });

            var $body = $('body');

            //Prevent links within link-enabled classes from bubbling the event up the dom to our link-enabled handler below
            $body.on('click', '.link-enabled a', function (e) {
                e.stopPropagation();
            });

            $body.on('click', '.link-enabled', function (e) {
                
                //Prevent default actions & navigate them to the preferred link if one exists, or the nearest anchor tag
                e.preventDefault();
                var $anchor;

                if ($(this).find('.preferred-link').exists()) {
                    var $preferredLink = $(this).find('.preferred-link');
                    //preferred-link can get added as a class through the RTE and will be added onto a new span tag in some instances
                    if ($preferredLink.is('a')) $anchor = $preferredLink;
                    else if ($preferredLink.find('a').exists()) $anchor = $preferredLink.find('a');
                } else {
                    $anchor = $(this).find('a');
                }

                if ($anchor !== null) {
                    var url = $anchor.attr('href');
                    var userTarget = $anchor.attr('target');
                    var smartTarget = url.startsWith("/") || url.startsWith("tel:") || url.startsWith("mailto:") ? "_self" : "_blank";
                    var target = userTarget || smartTarget;
                    window.open(url, target);
                } else {
                    console.warn('Anchor tag could not be found');
                }
            });
        }
    };

    return this;
})(jQuery);